import { menusReq } from '@/api/goods.js'
import local from '@/utils/local'

import $store from '../store'

export default function () {
  return new Promise(async resolve => {
    let cafeqr = local.get('cafeqr')
    menusReq(cafeqr).then(res => {
      let menu = res.data.filter(item => item.type == 2)[0]
      let book_menu = res.data.filter(item => item.type == 3)[0]
      if (menu?.categories) {
        for (const obj of menu.categories) {
          for (const key in menu.display_order) {
            if (obj.id == key) {
              obj.order = menu.display_order[key]
            }
          }
          for (const item of obj.items) {
            item.order = null
            for (const key in obj.display_order) {
              if (item.id == key) {
                item.order = obj.display_order[key]
              }
            }
          }
          obj.items.sort((a, b) => a.order - b.order)
        }

        menu.categories.sort((a, b) => a.order - b.order)

        for (const obj of menu.categories) {
          obj.items = obj.items.filter(item => {
            if (item.availabilities.length) {
              item.availabilities = item.availabilities.filter(
                available => available.menu_type == 2
              )[0]
            } else {
              item.availabilities = { is_available: true, is_sold_out: false }
            }

            return (
              item.is_combo == false &&
              item.availabilities.is_available &&
              item.available == 1
            )
          })
        }
        menu.categories = menu.categories.filter(menu => menu.items.length)
        $store.dispatch('app/A_init_goodlist', menu.categories)
      }

      if (book_menu?.categories) {
        for (const obj of book_menu.categories) {
          for (const key in book_menu.display_order) {
            if (obj.id == key) {
              obj.order = book_menu.display_order[key]
            }
          }

          for (const item of obj.items) {
            item.order = null
            for (const key in obj.display_order) {
              if (item.id == key) {
                item.order = obj.display_order[key]
              }
            }
          }
          obj.items.sort((a, b) => a.order - b.order)
        }

        book_menu.categories.sort((a, b) => a.order - b.order)

        for (const obj of book_menu.categories) {
          obj.items = obj.items.filter(item => {
            if (item.availabilities.length) {
              item.availabilities = item.availabilities.filter(
                available => available.menu_type == 3
              )[0]
            } else {
              item.availabilities = { is_available: true, is_sold_out: false }
            }

            return (
              item.is_combo == false &&
              item.availabilities.is_available &&
              item.available == 1
            )
          })
        }
        $store.dispatch('app/A_init_book_menu', book_menu.categories)
      }

      // if (book_menu?.categories) {
      //   for (const obj of book_menu.categories) {
      //     obj.items = obj.items.filter(item => item.is_combo == false)
      //   }
      //   local.set('book_menu', book_menu.categories)
      // }
      resolve()
    })
  })
}
